define("discourse/plugins/discourse-calendar/discourse/routes/discourse-post-event-upcoming-events-index", ["exports", "@ember/object", "@ember/service", "discourse/lib/url", "discourse/routes/discourse"], function (_exports, _object, _service, _url, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostEventUpcomingEventsIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "discoursePostEventApi", [_service.service]))();
    #discoursePostEventApi = (() => (dt7948.i(this, "discoursePostEventApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discoursePostEventService", [_service.service]))();
    #discoursePostEventService = (() => (dt7948.i(this, "discoursePostEventService"), void 0))();
    activate() {
      if (!this.siteSettings.discourse_post_event_enabled) {
        _url.default.redirectTo("/404");
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "activate", [_object.action]))();
    async model(params) {
      return await this.discoursePostEventService.fetchEvents(params);
    }
  }
  _exports.default = PostEventUpcomingEventsIndexRoute;
});