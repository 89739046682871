define("discourse/plugins/discourse-calendar/discourse/lib/add-recurrent-events", ["exports", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event"], function (_exports, _discoursePostEventEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addRecurrentEvents;
  /* eslint-disable no-console */

  function addRecurrentEvents(events) {
    try {
      return events.flatMap(event => {
        const upcomingEvents = event.upcomingDates?.map(upcomingDate => _discoursePostEventEvent.default.create({
          name: event.name,
          post: event.post,
          category_id: event.categoryId,
          starts_at: upcomingDate.starts_at,
          ends_at: upcomingDate.ends_at
        })) || [];
        return [event, ...upcomingEvents];
      });
    } catch (error) {
      console.error("Failed to retrieve events:", error);
      return [];
    }
  }
});