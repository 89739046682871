define("discourse/plugins/discourse-calendar/discourse/services/discourse-post-event-service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "discoursePostEventApi", [_service.service]))();
    #discoursePostEventApi = (() => (dt7948.i(this, "discoursePostEventApi"), void 0))();
    async fetchEvents() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (this.siteSettings.include_expired_events_on_calendar) {
        params.include_expired = true;
      }
      const events = await this.discoursePostEventApi.events(params);
      return await events;
    }
  }
  _exports.default = DiscoursePostEventService;
});