define("discourse/plugins/discourse-calendar/discourse/pre-initializers/rich-editor-extension", ["exports", "@ember/string", "discourse/lib/plugin-api", "discourse/plugins/discourse-calendar/discourse/initializers/discourse-post-event-decorator"], function (_exports, _string, _pluginApi, _discoursePostEventDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EVENT_ATTRIBUTES = {
    name: {
      default: null
    },
    start: {
      default: null
    },
    end: {
      default: null
    },
    reminders: {
      default: null
    },
    minimal: {
      default: null
    },
    closed: {
      default: null
    },
    status: {
      default: "public"
    },
    timezone: {
      default: "UTC"
    },
    allowedGroups: {
      default: null
    }
  };

  /** @type {RichEditorExtension} */
  const extension = {
    nodeSpec: {
      event: {
        attrs: EVENT_ATTRIBUTES,
        group: "block",
        defining: true,
        isolating: true,
        draggable: true,
        parseDOM: [{
          tag: "div.discourse-post-event",
          getAttrs(dom) {
            return {
              ...dom.dataset
            };
          }
        }],
        toDOM(node) {
          const element = document.createElement("div");
          element.classList.add("discourse-post-event");
          for (const [key, value] of Object.entries(node.attrs)) {
            if (value !== null) {
              element.dataset[key] = value;
            }
          }
          (0, _discoursePostEventDecorator.buildEventPreview)(element);
          return element;
        }
      }
    },
    parse: {
      wrap_bbcode(state, token) {
        if (token.tag === "div") {
          if (token.nesting === -1 && state.top().type.name === "event") {
            state.closeNode();
            return true;
          }
          if (token.nesting === 1 && token.attrGet("class") === "discourse-post-event") {
            const attrs = Object.fromEntries(token.attrs.filter(_ref => {
              let [key] = _ref;
              return key.startsWith("data-");
            }).map(_ref2 => {
              let [key, value] = _ref2;
              return [(0, _string.camelize)(key.slice(5)), value];
            }));
            state.openNode(state.schema.nodes.event, attrs);
            return true;
          }
        }
        return false;
      }
    },
    serializeNode: {
      event(state, node) {
        let bbcode = "[event";
        Object.entries(node.attrs).forEach(_ref3 => {
          let [key, value] = _ref3;
          if (value !== null) {
            bbcode += ` ${key}="${value}"`;
          }
        });
        bbcode += "]\n[/event]\n";
        state.write(bbcode);
      }
    }
  };
  var _default = _exports.default = {
    initialize() {
      (0, _pluginApi.withPluginApi)("2.1.1", api => {
        api.registerRichEditorExtension(extension);
      });
    }
  };
});